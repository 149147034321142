import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/faq.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    FAQs on mail forwarding and how it can ease your transition process
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Nov 7, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Virtual mailbox" className={styles.titleImage} />
                                    </div>


                                    <div class="entry-content">


                                        <p>There are hundreds of things to stress over when you move houses. You’ll need to sort through your piles of clutter, pack everything into boxes, and physically transfer your personal belongings from one place to another. The task can be daunting and exhausting. One of the things people forget to take care of during the move is to update their mailing addresses so they can receive their packages and deliveries at their new location.</p>



                                        <p>The time you are shuttling back and forth between addresses is like living in limbo. A lot of things can fall through the cracks, including important deliveries and documents. If you want to avoid missing any of your mail or packages, you can opt to hire the services of a mail forwarding company.&nbsp;</p>



                                        <p>In this article, we’ll discuss how forwarding services work and how it can help you make a smooth transition to a new address. If you want an efficient way to handle your mail while in transit, here is what you need to know:</p>



                                        <ol type="1"><li><strong>Decide if you want them to keep or forward your mail</strong></li></ol>



                                        <p>Parcel forwarding services can either hold your items for you or forward them to your new address immediately. When the service keeps your packages, you can have more control over when and where to get your items. Holding can be useful if you haven’t established your new address, or you will be living in a transient accommodation for some time before moving to your permanent one.</p>



                                        <ol type="1" start="2"><li><strong>Inquire about scanning service</strong></li></ol>



                                        <p>You might urgently need some documents, so ask the provider if they offer a page scanning service. You will have to authorize them to open your mail, however, so be wary of allowing them to see financial documents or credit card statements. However, for personal correspondence and non-sensitive documents from school or work, some providers can send electronic copies so you don’t have to wait to get them in person.</p>



                                        <ol type="1" start="3"><li><strong>Look for flexible contracts</strong></li></ol>



                                        <p>If you change addresses frequently, you will want a service that offers you discounts and deals for a longer contract period. Some services offer a per-use fee, which can be useful if you are only moving addresses once. If you tend to shuffle between different locations, you might want a more flexible plan that you can pay for monthly or annually.</p>



                                        <ol type="1" start="4"><li><strong>Ask for a demonstration</strong></li></ol>



                                        <p>Before you finalize a service, make sure to ask for a walkthrough or a demonstration. You will want to know where your mail is held, who will handle the packages, and how they properly store the items while waiting for pick-up. Familiarize yourself with the terms and conditions of the offer, verify how long they will safely store your package, and clarify what policies dictate destroying unclaimed mail.</p>



                                            <ol type="1" start="5"><li><strong>Check if they have integrated technology for notifications</strong></li></ol>



                                            <p>A good way to keep track of everything during your move is if your forwarding service can send you direct notifications through SMS or a digital app. Inquire about what methods they use for informing their customers about the status of your mail. This is especially important for time-sensitive documents and perishable packages you might have delivered to you.</p>



                                            <p>Your important documents make their way to you every month through snail mail. Losing them or misplacing them during your move can be a bigger burden and add unnecessary stress. Use a trusted parcel forwarding service and you will have one less moving item to worry about.</p>



                                            <p>Are you moving to a new address? Check out our intelligent <a href="http://hotsnail.com.au/">parcel forwarding service</a> that can get your valuable mail to you to any location from Australia. Sign up now for affordable pricing and flexible plans to suit your needs.</p>

		</div>

                                </Post>
                                    <Box className="d-flex" mt={4}>
                                        <BadgePost>FAQ</BadgePost>
                                        <BadgePost>Parcel forwarding</BadgePost>
                                        <BadgePost>Mail Scanning</BadgePost>
                                    </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
